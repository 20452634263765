html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  overflow: hidden;
  margin: 0;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

/* Shared colors across brands */
.error,
.failedValidation {
  color: #f44336;
}
.success,
.successfulValidation {
  color: green;
}
.negativeAmount {
  color: #a20000 !important;
}
.validationHelper {
  background-image: url(./svg/circleMark.svg);
  background-repeat: no-repeat;
  background-size: 1.5em;
  background-position: left center;
  padding-left: 2em;
  transition: 0.3s all ease-in-out;
}
.failedValidation {
  background-image: url(./svg/xMark.svg);
}
.successfulValidation {
  background-image: url(./svg/checkMark.svg);
}

/* Sizing and layout hacks for Material UI to work properly with our layout */
#root {
  height: 100%;
  background: #f2f2f2;
}
